import styles from "./styles.module.css";

export const CbreTitle = ({ children, ...props }) => (
  <h1 className={`${styles.title}`} {...props}>
    {children}
  </h1>
);

export const CbreSubtitle = ({ children, ...props }) => (
  <h2 className={`${styles.subtitle}`} {...props}>
    {children}
  </h2>
);

export const CbreCopy = ({ children, ...props }) => (
  <p className={`${styles.copy}`} {...props}>
    {children}
  </p>
);
