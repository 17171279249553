import { apiRequest } from "../authconfig";

export const requestApiAccessToken = async (instance, account) => {
  const request = {
    ...apiRequest,
    account,
  };
  let response;
  try {
    response = await instance.acquireTokenSilent(request);
  } catch (error) {
    response = await instance.acquireTokenPopup(request);
  }
  return response.accessToken;
};