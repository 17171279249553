import React from "react";
import {useSelector} from "react-redux";
import {Row, Col} from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import {LinkedinShareButton, LinkedinIcon, FacebookShareButton, FacebookIcon} from "react-share";

import CbreButton from "../../components/CbreButton";
import {CbreTitle, CbreCopy} from "../../components/CbreTypography";
import {selectVideoResponse, selectVideoId} from "../steps/stepsSlice";

const {REACT_APP_MINISITE_URL, REACT_APP_ASSET_BASE_URL} = process.env;

export function ResultsLeft() {
  const videoId = useSelector(selectVideoId);
  const videoResponse = useSelector(selectVideoResponse);
  const {url} = videoResponse;
  const miniSiteUrl = `${REACT_APP_MINISITE_URL}/${videoId}.html`;
  const videoPosterUrl = `${REACT_APP_ASSET_BASE_URL}/digitalassets/${videoId}-poster.jpg`;
  const videoUrl = `${REACT_APP_ASSET_BASE_URL}/digitalassets/${videoId}.mp4`;
  console.log(miniSiteUrl)
  console.log("[FE]assets", url);
  console.log(videoId);
  return (
    <Row>
      <Col>
        <CbreTitle>Share your video </CbreTitle>
        <CbreCopy>
          For best results download the video and upload to your social channel. Or, click the LinkedIn or Facebook logo
          below to share your video directly (video will play once clicked).
          <br/>
          <br/>
          Use the <b>#TogetherCBRE</b> hashtag when you create your own caption or use the suggested copy below:
          <br/>
          <span style={{marginLeft: '16px', display: 'block'}}>Everyday, we help businesses, communities and people thrive. Watch the video to see how I realize potential through the work I do. <br/><b>#TogetherCBRE</b></span>
          <br/>
          
        </CbreCopy>
      </Col>
      <Col style={{display: "flex", alignItems: "center"}}>
        <CbreButton
          href={url}
          download="social-video.mp4"
          target="_blank"
          rel="noreferrer"
          title="download your video"
        >
          Download <DownloadOutlined/>
        </CbreButton>
        <LinkedinShareButton url={miniSiteUrl} style={{marginLeft: '16px', marginRight: '12px'}} source={videoPosterUrl}
        media={{
            type:'video',
            src:videoUrl
          }}>
          <LinkedinIcon
            size={80}
            round={false}
            iconFillColor="rgb(0, 63, 45)"
            bgStyle={{fill: "transparent"}}
          />
        </LinkedinShareButton>
        <FacebookShareButton url={miniSiteUrl} source={videoPosterUrl}
        media={{
            type:'video',
            src:videoUrl
          }}>
          <FacebookIcon
            size={80}
            round={false}
            iconFillColor="rgb(0, 63, 45)"
            bgStyle={{fill: "transparent"}}
          />
        </FacebookShareButton>
      </Col>
    </Row>
  );
}
