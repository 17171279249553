import { Col } from "antd";
import styles from "./styles.module.css";

const Layout = ({ children }) => {
  return <div className={styles.layout}>{children}</div>;
};

export const LeftPanel = ({ children }) => {
  return (
    <Col xs={24} lg={12} className={styles.leftPanel}>
      {children}
    </Col>
  );
};

export const RightPanel = ({ children }) => {
  return (
    <Col xs={24} lg={12} className={styles.rightPanel}>
      {children}
    </Col>
  );
};

export const ActionBar = ({ children, fixed }) => {
  return (
    <div className={fixed ? styles.actionBarFixed : styles.actionBar}>
      {children}
    </div>
  );
};

export default Layout;
