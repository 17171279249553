import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Row, Col } from "antd";
import { AuthenticatedTemplate } from "@azure/msal-react";
import Layout from "./components/Layout";
import Content from "./components/Content";
import { Topbar } from "./components/Topbar";
import ScrollToTop from "./features/scroll-to-top/ScrollToTop";
import { StepsLeft, StepsRight } from "./features/steps/Steps";
import { WelcomeLeft } from "./features/welcome/WelcomeLeft";
import { WelcomeRight } from "./features/welcome/WelcomeRight";
import { ResultsLeft } from "./features/results/ResultsLeft";
import { ResultsRight } from "./features/results/ResultsRight";
import "./App.css";

function App() {
  return (
    <Router>
      <ScrollToTop>
        <Layout>
          <Topbar></Topbar>
          <Content>
            <Col xs={24} lg={12} className="left-col">
              <Row>
                <Col span={24}>
                  <Switch>
                    <Route exact path="/">
                      <WelcomeLeft />
                    </Route>
                    <AuthenticatedTemplate>
                      <Route exact path="/results">
                        <ResultsLeft />
                      </Route>
                      <Route path="/step/:id" children={<StepsLeft />} />
                    </AuthenticatedTemplate>
                  </Switch>
                </Col>
              </Row>
            </Col>
            <Col xs={24} lg={12} id="right-col" className="right-col">
              <Switch>
                <Route exact path="/">
                  <WelcomeRight />
                </Route>
                <AuthenticatedTemplate>
                  <Route exact path="/results">
                    <ResultsRight />
                  </Route>
                  <Route path="/step/:id" children={<StepsRight />} />
                </AuthenticatedTemplate>
              </Switch>
            </Col>
          </Content>
        </Layout>
      </ScrollToTop>
    </Router>
  );
}

export default App;