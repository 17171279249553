import React from "react";
import { useSelector } from "react-redux";
import { Row, Col } from "antd";

import { selectVideoResponse, selectVideoId } from "../steps/stepsSlice";
const {REACT_APP_ASSET_BASE_URL} = process.env;

function MediaDisplay() {
  const videoId = useSelector(selectVideoId);
  const posterUrl = `${REACT_APP_ASSET_BASE_URL}/digitalassets/${videoId}-poster.jpg`;
  const mediaUrl = `${REACT_APP_ASSET_BASE_URL}/digitalassets/${videoId}.mp4`;
  return (
    <video controls poster={posterUrl} autoPlay="true" muted style={{width: '100%'}}>
      <source src={mediaUrl} type="video/mp4" />
    </video>
  );
}

export function ResultsRight() {
  const videoResponse = useSelector(selectVideoResponse);
  return (
    <>
      <Row justify="center" align="start" style={{ height: "100%" }}>
        <Col>
          <MediaDisplay />
        </Col>
      </Row>
    </>
  );
}
