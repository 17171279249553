import { get, post } from "axios";
const { REACT_APP_API_URL } = process.env;
const API_URL = REACT_APP_API_URL;

export async function fetchUploadUrl(accessToken) {
  try
  {
    const response = await get(`${API_URL}/uploads`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    console.log("fetchUploadUrl succeed",response);
    return response.data.uploadURL;
  }catch(error)
  {
    console.error(error);
  }
}

export function fetchStatus() {
  return get(`${API_URL}/status`);
}

export function pollVideoStatus(renderId, accessToken) {
  const statusConfig = { renderId };
  return post(`${API_URL}/getVideoStatus`, statusConfig, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

export function uploadVideo(videoConfig, accessToken) {
  const config = JSON.stringify(videoConfig);

  return post(`${API_URL}/generateVideo`, config, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${accessToken}`,
    },
  });
}
