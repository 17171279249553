import {Button, Tooltip} from "antd";

import styles from "./styles.module.css";

const CbreButton = ({children, tooltipText = null, ...props}) => (
  <Button className={styles.cbreButton} {...props}>
    {tooltipText ? <Tooltip title={tooltipText} placement="left">{children}</Tooltip> : children}
  </Button>
);

export default CbreButton;
