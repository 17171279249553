import React, { useEffect } from "react";
import { Row } from "antd";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateEmail } from "../steps/stepsSlice";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import { SignInButton } from "../../components/SignInButton";
import CbreButton from "../../components/CbreButton";
import { ActionBar } from "../../components/Layout";

export function WelcomeRight() {
  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      const email = accounts[0].username;
      dispatch(updateEmail(email));
    }
  }, [isAuthenticated, accounts]);
  return (
    <Row align="middle" justify="center" style={{ flexGrow: 1 }} fixed={true}>
      {isAuthenticated ? (
        <ActionBar fixed>
          <Link to="/step/1">
            <CbreButton>Start</CbreButton>
          </Link>
        </ActionBar>
      ) : (
        <ActionBar fixed>
          <SignInButton />
        </ActionBar>
      )}
    </Row>
  );
}
