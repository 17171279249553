import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateStep } from "../stepsSlice";
import { Row, Col, Form } from "antd";
import { Link } from "react-router-dom";

import { ActionBar } from "../../../components/Layout";
import { CbreTitle } from "../../../components/CbreTypography";
import CbreButton from "../../../components/CbreButton";
import { RegionSelect, LocationSelect } from "../../../components/CbreSelect";
import { regions } from "../../../utils/locations";

export function Left() {
  return (
    <Row>
      <Col>
        <CbreTitle>Select your location</CbreTitle>
      </Col>
    </Row>
  );
}

export function Right() {
  const dispatch = useDispatch();
  const [locations, setLocations] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);
  function onLocationChange(value) {
    dispatch(updateStep({ step: 1, answer: value }));
  }

  function onRegionSelect(value) {
    setSelectedRegion(value);
    const currentLocations = regions.find(
      (region) => region.name === value
    ).locations;
    setLocations(currentLocations);
  }

  return (
    <>
      <Row justify="center" align="top" style={{ width: "100%" }}>
        <Col xs={24} lg={12}>
          <div className="scroll-container">
            <Form>
              <RegionSelect regions={regions} onChange={onRegionSelect} />
              <LocationSelect
                disabled={!selectedRegion}
                locations={locations}
                onChange={onLocationChange}
              />
            </Form>
          </div>
        </Col>
        <Col span={24}>
          <ActionBar fixed>
            <Link to="/step/2" className="form-submit">
              <CbreButton>Next Question</CbreButton>
            </Link>
          </ActionBar>
        </Col>
      </Row>
    </>
  );
}
