import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateStep, selectAnswerThree } from "../stepsSlice";
import { Row, Col, Form, notification } from "antd";
import { Link } from "react-router-dom";
import {
  CbreTitle,
  CbreSubtitle,
  CbreCopy,
} from "../../../components/CbreTypography";
import CbreButton from "../../../components/CbreButton";
import { ActionBar } from "../../../components/Layout";
import { WorkCheck } from "../../../components/CbreCheck";

export function Left() {
  return (
    <Row>
      <Col span={24}>
        <CbreTitle>I realize potential</CbreTitle>
        <CbreSubtitle>(My interests)</CbreSubtitle>
      </Col>
    </Row>
  );
}

export function Right() {
  const answer = useSelector(selectAnswerThree);
  const dispatch = useDispatch();
  const answers = [
    "in sustainability",
    "in my community",
    "in the great outdoors",
    "in giving back",
    "by patronizing the arts",
    "by discovering new places",
    "by being physically active",
    "as a board member",
    "as a book lover",
    "as a sports enthusiast",
    "as a food lover",
    "as an animal lover",
    "as a creative",
    "by spending time with loved ones",
    "by connecting with new people",
    "through design",
    "through culinary arts",
    "through meditation",
    "through fitness + wellness",
    "through interior design",
    "through music",
    "in all things digital",
    "supporting social justice",
    "through teaching",
    "through learning",
    "as a film + movie lover",
    "as a plant lover",
  ];

  function onChange(checkedValues) {
    if (checkedValues.length <= 3) {
      dispatch(updateStep({ step: 3, answer: checkedValues }));
    } else {
      notification.info({
        description: "You can only select up to 3 options.",
      });
    }
  }

  return (
    <Row justify="center" align="top" style={{ width: "100%" }}>
      <Col style={{ width: "100%", height: "100%" }}>
        <div className="scroll-container" style={{ height: "100%" }}>
          <Form style={{ height: "100%" }}>
            <div>
              <div style={{ display: "flex", justifyContent: "start" }}>
                <CbreCopy>Choose up to 3</CbreCopy>
              </div>
              <WorkCheck
                options={answers}
                onChange={onChange}
                defaultValue={answer}
              />
            </div>
            <ActionBar>
              <Link to="/step/4" className="form-submit">
                <CbreButton>Finish & Preview</CbreButton>
              </Link>
            </ActionBar>
          </Form>
        </div>
      </Col>
    </Row>
  );
}
