import { React, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Spin, Progress } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

import {
  CbreTitle,
  CbreCopy,
} from "../../../components/CbreTypography";
import { requestApiAccessToken } from "../../../utils/auth";
import {
  selectHeadshot,
  selectVideoStatus,
  selectVideoId,
  selectVideoResponse,
  selectAnswerOne,
  selectAnswerTwo,
  selectAnswerThree,
  selectRenderProgress,
  updateRenderProgress,
  fetchVideoStatus,
  uploadVideoConfig,
} from "../stepsSlice";

// let renderProgress = 5;
const loadingMessages = {
  fetching: "Uploading Assets",
  queued: "Queued",
  rendering: "Rendering Video",
  saving: "Saving Video",
  done: "Ready",
};

export function Left() {
  const renderProgress = useSelector(selectRenderProgress);
  const videoStatus = useSelector(selectVideoStatus);
  return (
    <Row>
      <Col>
        <CbreTitle>Generating video</CbreTitle>
        <CbreCopy level={3}>
          {videoStatus && loadingMessages[videoStatus.toLowerCase()]}
        </CbreCopy>
        <Progress
          percent={renderProgress}
          strokeColor={{
            from: "rgb(0, 63, 45)",
            to: "rgb(0, 63, 45)",
          }}
          showInfo={false}
        />
      </Col>
    </Row>
  );
}

export function Right() {
  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const { instance, accounts } = useMsal();
  const history = useHistory();
  const dispatch = useDispatch();
  const answerOne = useSelector(selectAnswerOne);
  const answerTwo = useSelector(selectAnswerTwo);
  const answerThree = useSelector(selectAnswerThree);
  const headshot = useSelector(selectHeadshot);
  const renderProgress = useSelector(selectRenderProgress);
  // const email = useSelector(selectEmail);
  
  // NOTE: the declaration below is needed to make the video polling work.
  // not sure why this is, it could that selecting the video response from the
  // state causes a rerender or something similar.
  // when this is removed the video polling effect only fires once.
  const videoResponse = useSelector(selectVideoResponse);
  const videoStatus = useSelector(selectVideoStatus);
  const videoId = useSelector(selectVideoId);
  const config = {
    answerOne,
    answerTwo,
    answerThree,
  };
  
  if (typeof headshot === 'string' && headshot.length) {
    config.headshot = headshot;
  }

  const spinIcon = (
    <LoadingOutlined style={{ fontSize: 150, color: "var(--cbre-green)" }} spin />
  );
  
  useEffect(() => {
    async function startVideoUploadFlow() {
      const token = await requestApiAccessToken(instance, accounts[0]);
      setAccessToken(token);
      dispatch(uploadVideoConfig({ config, accessToken: token }));
    }
    startVideoUploadFlow();
  }, [instance, accounts]);

  useEffect(() => {
    if (videoId && accessToken) {
      async function startStatusPolling() {
        dispatch(fetchVideoStatus({ videoId, accessToken }));
      }
      startStatusPolling();
    }
  }, [videoId, accessToken]);

  useEffect(() => {
    if (!videoId || !accessToken) return;
    if (
      videoId &&
      (videoStatus === "rendering" )
    ) {
      setCurrentTimeout(setTimeout(() => {
        // renderProgress += (100 - renderProgress) / 2.5;
        dispatch(updateRenderProgress(renderProgress + (100 - renderProgress) / 2.5));
        dispatch(fetchVideoStatus({ videoId, accessToken }));
      }, 5000));
    } else if (videoStatus === "success") {
      // renderProgress = 100;
      dispatch(updateRenderProgress(100));
      setTimeout(()=> {
        history.push("/results");
      }, 1000);
    }

    // cleanup
    return () => {
      clearTimeout(currentTimeout);
    }
  }, [videoId, videoStatus, renderProgress, dispatch, history, accessToken]);

  return (
    <Row justify="center" align="center" style={{ height: "100%", paddingTop: "80px" }}>
      <Col>
        <Spin indicator={spinIcon} />
      </Col>
    </Row>
  );
}
