const {
  NODE_ENV,
  REACT_APP_AZURE_CLIENT_ID,
  REACT_APP_AZURE_AUTHORITY,
  REACT_APP_AZURE_REDIRECT_URI_DEV,
  REACT_APP_AZURE_REDIRECT_URI_PROD,
  REACT_APP_AZURE_LOGIN_SCOPES,
  REACT_APP_AZURE_API_REQUEST_SCOPES,
} = process.env;

export const msalConfig = {
  auth: {
    clientId: REACT_APP_AZURE_CLIENT_ID,
    authority: REACT_APP_AZURE_AUTHORITY, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri:
      NODE_ENV === "production"
        ? REACT_APP_AZURE_REDIRECT_URI_PROD
        : REACT_APP_AZURE_REDIRECT_URI_DEV,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"],
  // scopes: REACT_APP_AZURE_LOGIN_SCOPES.split(" "),
};

// Add scopes here for Access token to be used for API calls to AWS
export const apiRequest = {
  // scopes: ["api://ngoo5vpwz0.execute-api.us-east-1.amazonaws.com/dev/CBRE.VideoUpload"],
  scopes: [REACT_APP_AZURE_API_REQUEST_SCOPES],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
