export const regions = [
  {
    name: "Americas",
    locations: [
      { name: "Argentina", options: ["Buenos Aires"] },
      {
        name: "Brazil",
        options: [
          "Brasilia",
          "Belo Horizonte",
          "Curitiba, Parana",
          "Rio de Janeiro",
          "São Paulo",
        ],
      },
      {
        name: "Canada",
        options: [
          "Calgary",
          "Edmonton",
          "Fredericton",
          "Halifax",
          "Kitchener",
          "London",
          "Mississauga",
          "Moncton",
          "Montreal",
          "Oakville",
          "Ottawa",
          "Regina",
          "Saint-Laurent",
          "Saskatoon",
          "St. John's",
          "Toronto",
          "Vancouver",
          "Victoria",
          "Windsor",
          "Winnipeg",
        ],
      },
      { name: "Chilé", options: ["Las Condes"] },
      { name: "Colombia", options: ["Bogota"] },
      { name: "Costa Rica", options: ["San José", "San Antonio"] },
      {
        name: "Latin America",
        options: ["Panama", "Venezula", "Costa Rica", "Peru"],
      },
      {
        name: "Mexico",
        options: [
          "Alvaro Obregon",
          "Miguel Hidalgo",
          "Ciudad de Mexico",
          "San Pedro Garza Garcia",
          "Zapopan",
        ],
      },
      { name: "Venezuela", options: ["Caracas"] },
    ],
  },
  {
    name: "Asia Pacific",
    locations: [
      {
        options: [
          "Adelaide",
          "Botany",
          "Brisbane",
          "Cairns",
          "Canberra",
          "Maroochydore",
          "Melbourne",
          "North Sydney",
          "Parramatta",
          "Perth",
          "Springwood",
          "Surfers Paradise",
          "Sydney",
        ],
        name: "Australia",
      },
      {
        name: "China",
        options: [
          "Beijing ",
          "Chengdu",
          "Guangzhou",
          "Hangzhou",
          "High-tech District, Xi'an",
          "NanJing",
          "Pudong District, Shanghai",
          "Shanghai",
          "Shenyang",
          "Shenzhen",
          "Suzhou",
          "Tianjin",
          "Wuhan",
          "Zhengzhou",
        ],
      },
      {
        name: "Hong Kong",
        options: ["Admiralty", "Kwun Tong, Kowloon"],
      },
      {
        options: [
          "Ahmedabad",
          "Bangalore",
          "Bhubaneswar",
          "Chandigarh",
          "Chennai",
          "Coimbatore",
          "Gurgaon",
          "Gurugram",
          "Hyderabad",
          "Indore, Madhya Pradesh",
          "Jaipur",
          "Kochi",
          "Kolkata",
          "Lucknow",
          "Mattaur, Mohali",
          "Mumbai",
          "New Delhi",
          "Pune",
        ],
        name: "India",
      },
      { options: ["Jakarta Selatan"], name: "Indonesia" },
      {
        options: [
          "Chiyoda City, Tokyo",
          "Fukuoka",
          "Hiroshima",
          "Kanazawa-shi",
          "Kita-ku, Osaka",
          "Nagoya-shi",
          "Sapporro-shi",
          "Sendai-shi",
          "Tokyo",
          "Yokohama-shi",
        ],
        name: "Japan",
      },
      { options: ["Seoul"], name: "Korea" },
      { options: ["Kuala Lumpur", "Petaling Jaya"], name: "Malaysia" },
      {
        options: [
          "Auckland",
          "Blenheim",
          "Central Christchurch",
          "Christchurch",
          "Dunedin",
          "Gisborne",
          "Hamilton",
          "Invercargill",
          "Napier",
          "Nelson",
          "New Plymouth",
          "Palmerston North",
          "Paraparaumu",
          "Rangiora",
          "Rosedale",
          "Rotorua",
          "Tauranga",
          "Te Aro",
          "Timaru",
          "Wellington",
          "Westgate",
          "Whangarei",
        ],
        name: "New Zealand",
      },
      { options: ["Makati City"], name: "Philippines" },
      { options: ["Paya Lebar Quarter", "Singapore"], name: "Singapore" },
      { options: ["Colombo"], name: "Sri Lanka" },
      { options: ["Taipei"], name: "Taiwan" },
      {
        options: [
          "Bangkok",
          "Bangkok, Phatumwan",
          "Phatumwan District, Bangkok",
          "Phuket",
        ],
        name: "Thailand",
      },
      {
        options: [
          "Da Nang City",
          "Hanoi",
          "Hanoi City",
          "Ho Chi Minh City, Ben Thanh Ward, District 1",
        ],
        name: "Vietnam",
      },
    ],
  },
  {
    name: "Europe",
    locations: [
      { options: ["Vienna", "Kundl", "Salzburg"], name: "Austria" },
      { options: ["Brussels", "Antwerp", "Diegem"], name: "Belgium" },
      { options: ["Sofia"], name: "Bulgaria" },
      { options: ["Zagreb"], name: "Croatia" },
      { options: ["Chodov, Prague", "Prague"], name: "Czech Republic" },
      {
        options: [
          "Aalborg",
          "Arhus C",
          "Brande",
          "Copenhagen",
          "Esbjerg",
          "Greve",
          "Grindsted",
          "Hasselager",
          "Hedensted",
          "Herning",
          "Holstebro",
          "Horsens",
          "Kolding",
          "Munkebo",
          "Nakskov",
          "Nykøbing Falster",
          "Randers",
          "Rodekro",
          "Viborg",
        ],
        name: "Denmark",
      },
      { options: ["Helinski"], name: "Finland" },
      {
        options: [
          "Aix-en-Provence",
          "Bordeaux",
          "Courbevoie",
          "Lille",
          "Lyon",
          "Marseille",
          "Montrouge",
          "Nantes",
          "Nice",
          "Paris",
          "Saint-Denis",
          "Toulouse",
          "Valbonne",
        ],
        name: "France",
      },
      {
        options: [
          "Berlin",
          "Dusseldorf",
          "Essen",
          "Frankfurt",
          "Frankfurt am Main",
          "Hamburg",
          "Köln",
          "Leipzig",
          "Lorsch",
          "Munich",
          "Neuenburg",
          "Stuttgart",
        ],
        name: "Germany",
      },
      { options: ["Attiki"], name: "Greece" },
      { options: ["Budapest"], name: "Hungary" },
      { options: ["Dublin"], name: "Ireland" },
      {
        options: [
          "Acquaviva delle Fonti",
          "Calderara di Reno (BO)",
          "Campi Bisenzio",
          "Città Sant'Angelo",
          "Deruta",
          "Fontanafredda",
          "Grosseto",
          "Milan",
          "Milano",
          "Modena",
          "Montesilvano",
          "Padova",
          "Roma",
          "Rome",
          "Settimo Torinese, Torino",
          "Trieste (TS)",
          "Udine",
          "Verona",
        ],
        name: "Italy",
      },
      { options: ["Luxembourg"], name: "Luxembourg" },
      {
        options: [
          "Barendrecht",
          "Amsterdam",
          "Den Haag",
          "Eindhoven",
          "Groningen",
          "Hardenberg",
          "Rotterdam",
          "Schiphol",
          "Schiphol-Rijk",
          "Utrecht",
          "Zwolle",
        ],
        name: "Netherlands",
      },
      { options: ["Oslo"], name: "Norway" },
      {
        options: [
          "Gdansk",
          "Katowice",
          "Krakow",
          "Lodz",
          "Poznan",
          "Warsaw",
          "Wroclaw",
        ],
        name: "Poland",
      },
      { options: ["Lisboa", "Porto"], name: "Portugal" },
      { options: ["Bucharest"], name: "Romania" },
      { name: "Serbia", options: ["Belgrade"] },
      {
        options: ["Bratislava", "Banská Bystrica", "Košice"],
        name: "Slovakia",
      },
      {
        options: [
          "Alicante",
          "Barcelona",
          "Bilbao, Vizcaya",
          "Madrid",
          "Madrid, Community of Madrid",
          "Malaga",
          "Palma de Mallorca",
          "Sevilla",
          "Seville",
          "Valencia",
          "Zaragoza",
        ],
        name: "Spain",
      },
      {
        options: [
          "Gothenburg",
          "Handen",
          "Malmo",
          "Stockholm",
          "Stockholm ",
          "Uppsala",
        ],
        name: "Sweden",
      },
      {
        options: [
          "Basel",
          "Geneva",
          "Lausanne",
          "Muttenz",
          "Pratteln",
          "Zug",
          "Zurich",
        ],
        name: "Switzerland",
      },
      {
        options: [
          "Aberdeen",
          "Birmingham",
          "Bristol",
          "Edinburgh",
          "Glasgow",
          "Leeds",
          "Liverpool",
          "London",
          "Manchester",
          "Newbury",
          "Oxford",
          "Redditch",
          "Southampton",
          "Stratford, London",
          "Waltham Cross",
        ],
        name: "United Kingdom",
      },
    ],
  },
  {
    name: "Middle East & Africa",
    locations: [
      { options: ["Manama", "Budaiya,"], name: "Bahrain" },
      { options: ["South Africa"], name: "Botswana" },
      { options: ["New Cairo"], name: "Egypt" },
      { options: ["Bnei Brak", "Ramat-Gan", "Tel Aviv-Yafo"], name: "Israel" },
      { options: ["Casablanca"], name: "Morocco" },
      { options: ["Muscat"], name: "Oman" },
      { options: ["Karachi"], name: "Pakistan" },
      { options: ["Riyadh"], name: "Saudi Arabia" },
      { options: ["Istanbul"], name: "Turkey" },
      { options: ["Dubai", "Abu Dhabi"], name: "United Arab Emirates" },
    ],
  },
  {
    name: "USA",
    locations: [
      { options: ["Birmingham"], name: "Alabama" },
      { options: ["Phoenix", "Tuscon"], name: "Arizona" },
      { options: ["Fayetteville", "Little Rock"], name: "Arkansas" },
      {
        options: [
          "Burlingame",
          "Calabasas",
          "Carlsbad",
          "Dublin",
          "El Segundo",
          "Encino",
          "Fresno",
          "Glendale",
          "Irvine",
          "Laguna Hills",
          "Lake Forest",
          "Los Angeles",
          "Newport Beach",
          "Oakland",
          "Ontario",
          "Oxnard",
          "Palo Alto",
          "Roseville",
          "Sacramento",
          "San Diego",
          "San Francisco",
          "San Jose",
          "Santa Fe Springs",
          "Stockton",
          "Union City",
          "Valencia",
          "Walnut Creek",
          "Woodland Hills",
        ],
        name: "California",
      },
      {
        options: [
          "Boulder",
          "Colorado Springs",
          "Denver",
          "Fort Collins",
          "Greenwood Village",
        ],
        name: "Colorado",
      },
      { options: ["Hartford", "Norwalk", "Stamford"], name: "Connecticut" },
      { options: ["Wilmington"], name: "Delaware" },
      { options: ["Washington"], name: "District of Columbia" },
      {
        options: [
          "Boca Raton",
          "Fort Lauderdale",
          "Jacksonville",
          "Jupiter",
          "Miami",
          "Miramar Beach",
          "Orlando",
          "Tallahassee",
          "Tampa",
          "West Palm Beach",
        ],
        name: "Florida",
      },
      {
        options: [
          "Alpharetta",
          "Atlanta",
          "Johns Creek",
          "Norcross",
          "Savannah",
        ],
        name: "Georgia",
      },
      { options: ["Honolulu"], name: "Hawaii" },
      { options: ["Boise"], name: "Idaho" },
      { options: ["Chicago", "Lake Forest", "Oak Brook"], name: "Illinois" },
      { options: ["Indianapolis"], name: "Indiana" },
      { options: ["West Des Moines"], name: "Iowa" },
      { options: ["Louisville"], name: "Kentucky" },
      { options: ["Metairie"], name: "Louisiana" },
      {
        options: ["Annapolis", "Baltimore", "Bethesda", "Landover"],
        name: "Maryland",
      },
      {
        options: ["Andover", "Boston", "Cambridge", "Framingham"],
        name: "Massachusetts",
      },
      { options: ["Southfield", "Grand Rapids"], name: "Michigan" },
      { options: ["Bloomington", "Minneapolis"], name: "Minnesota" },
      { options: ["Flowood"], name: "Mississippi" },
      { options: ["Clayton", "Kansas"], name: "Missouri" },
      { options: ["Omaha"], name: "Nebraska" },
      { options: ["Las Vegas", "Reno", "Melville"], name: "Nevada" },
      { options: ["Manchester"], name: "New Hampshire" },
      {
        options: [
          "East Brunswick",
          "Florham Park, Morris County",
          "Mount Laurel",
          "Saddle Brook",
          "Wall Township",
        ],
        name: "New Jersey",
      },
      { options: ["Albuquerque"], name: "New Mexico" },
      {
        options: ["New York"],
        name: "New York",
      },
      {
        options: ["Charlotte", "Durham", "Morrisville", "Raleigh"],
        name: "North Carolina",
      },
      { options: ["Fargo"], name: "North Dakota" },
      {
        options: ["Canton", "Cincinnati", "Cleveland", "Columbus", "Fairlawn"],
        name: "Ohio",
      },
      { options: ["Oklahoma City", "Tulsa"], name: "Oklahoma" },
      { options: ["Portland", "Lake Oswego"], name: "Oregon" },
      {
        options: [
          "Allentown",
          "Fort Washington",
          "Harrisburg",
          "Philadelphia",
          "Pittsburgh",
          "Radnor",
          "West Conshohocken",
        ],
        name: "Pennsylvania",
      },
      { options: ["Providence"], name: "Rhode Island" },
      {
        options: ["Charleston", "Columbia", "Greenville"],
        name: "South Carolina",
      },
      { options: ["Sioux Falls"], name: "South Dakota" },
      {
        options: ["Chattanooga", "Knoxville", "Memphis", "Nashville"],
        name: "Tennessee",
      },
      {
        options: [
          "Austin",
          "Dallas",
          "El Paso",
          "Fort Worth",
          "Houston",
          "Mission",
          "Richardson",
          "San Antonio",
        ],
        name: "Texas",
      },
      { options: ["Lehi", "Salt Lake City"], name: "Utah" },
      {
        options: ["Arlington", "Norfolk", "Richmond", "Tysons"],
        name: "Virginia",
      },
      {
        options: [
          "Bellevue",
          "Bothell",
          "Kent",
          "Redmond",
          "Seattle",
          "Spokane",
          "Tacoma",
          "Vancouver",
        ],
        name: "Washington",
      },
      { options: ["Charleston"], name: "West Virginia" },
      {
        options: ["Brookfield", "Madison", "Menomonee Falls", "Milwaukee"],
        name: "Wisconsin",
      },
    ],
  },
];
