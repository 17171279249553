import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authconfig";
// import { Button, Typography } from "antd";
// const { Title } = Typography;
import CbreButton from "./CbreButton";
/**
 * Renders a button which, when selected, will open a popup for login
 */

export const SignInButton = () => {
  const { instance } = useMsal();


  const handleLogin = async (loginType) => {
    console.log("[FE]Testing", loginType);
    if (loginType === "popup") {
      console.log("[FE]Login Reqest");
      await instance.loginPopup(loginRequest).catch((e) => {
        console.log("[FE]Login Error", e);
      });
    }
    
  };
  return (
    <CbreButton onClick={() => handleLogin("popup")}>
      Sign in
    </CbreButton>
  );
};
