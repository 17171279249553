import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateStep, selectAnswerTwo } from "../stepsSlice";
import { Row, Col, Form, notification } from "antd";
import { Link } from "react-router-dom";

import { ActionBar } from "../../../components/Layout";
import {
  CbreTitle,
  CbreSubtitle,
  CbreCopy,
} from "../../../components/CbreTypography";
import { WorkCheck } from "../../../components/CbreCheck";
import CbreButton from "../../../components/CbreButton";

export function Left() {
  return (
    <Row style={{ width: "100%" }}>
      <Col style={{ width: "100%" }}>
        <CbreTitle>I realize potential in</CbreTitle>
        <CbreSubtitle>(My work at CBRE)</CbreSubtitle>
      </Col>
    </Row>
  );
}

export function Right() {
  const answer = useSelector(selectAnswerTwo);
  const dispatch = useDispatch();
  const answers = [
    "helping people thrive at CBRE",
    "using my creativity to create value",
    "helping clients see all dimensions of real estate",
    "being a wise advisor to clients",
    "managing risk and delivering actionable counsel ",
    "bringing innovative client solutions to life",
    "making client solutions more sustainable",
    "delivering superior outcomes at every stage of a building’s lifecycle",
    "transforming industries with technology",
    "shaping how the world sees CBRE",
    "using data-driven insights to inform fiscal solutions",
    "sourcing and developing buildings of the future ",
    "creating solutions that maximize performance across properties",
    "creating a diverse, equitable and inclusive workplace",
    "implementing responsible practices that impact ESG",
    "accomplishing what others consider impossible",
  ];

  function onChange(checkedValues) {
    if (checkedValues.length <= 2) {
      dispatch(updateStep({ step: 2, answer: checkedValues }));
    } else {
      notification.info({
        description: "You can only select up to 2 options.",
      });
    }
  }

  return (
    <Row justify="center" align="top" style={{ width: "100%" }}>
      <style
        dangerouslySetInnerHTML={{
          __html: `
            .ant-checkbox-group {
              grid-template-columns: repeat(1, 1fr);
            }

            @media (min-width: 1179px) {
              .ant-checkbox-group {
                grid-template-columns: repeat(2, 1fr);
              }
            }
          `,
        }}
      />
      <Col style={{ width: "100%", height: "100%" }}>
        <div className="scroll-container" style={{ height: "100%" }}>
          <Form style={{ height: "100%" }}>
            <div>
              <div style={{ display: "flex", justifyContent: "start" }}>
                <CbreCopy>Choose up to 2</CbreCopy>
              </div>
              <WorkCheck
                options={answers}
                onChange={onChange}
                defaultValue={answer}
              />
            </div>
            <ActionBar>
              <Link to="/step/3" className="form-submit">
                <CbreButton>Next Question</CbreButton>
              </Link>
            </ActionBar>
          </Form>
        </div>
      </Col>
    </Row>
  );
}
