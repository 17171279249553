import React, {useEffect, useRef, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {Cropper} from "react-cropper";
import {useMsal} from "@azure/msal-react";
import {decode} from "base-64";
import {Row, Col, Upload, Spin, Space, notification} from "antd";
import {
  CloudUploadOutlined,
  LoadingOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";

import {
  CbreTitle,
  CbreSubtitle,
  CbreCopy,
} from "../../../components/CbreTypography";
import {ActionBar} from "../../../components/Layout";
import CbreButton from "../../../components/CbreButton";
import {fetchUploadUrl} from "../../steps/stepsAPI";
import {updateHeadshot, updateRenderProgress, updateVideoId, updateVideoStatus} from "../stepsSlice";
import {requestApiAccessToken} from "../../../utils/auth";

import "cropperjs/dist/cropper.css";

const MAX_IMAGE_SIZE = 3.5; // MB

const {Dragger} = Upload;

export function Left() {
  return (
    <Row>
      <Col>
        <CbreTitle>Last step</CbreTitle>
        <CbreCopy>We recommend using a professional headshot</CbreCopy>
      </Col>
    </Row>
  );
}

export function Right() {
  // state
  const [complete, setComplete] = useState(false);
  const [cropper, setCropper] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [scaleX, setScaleX] = useState(1);
  const [dragMode, setDragMode] = useState("crop");
  
  const dispatch = useDispatch();
  const history = useHistory();
  const {instance, accounts} = useMsal();
  
  const cropRef = useRef(null);
  const spinIcon = (
    <LoadingOutlined
      style={{fontSize: 150, color: "var(--cbre-green)"}}
      spin
    />
  );
  const draggerProps = {
    accept: "image/*",
    method: "put",
    name: "headshot",
    multiple: false,
    showUploadList: false,
    customRequest: customRequest,
    style: {padding: "60px", marginBottom: "50px", aspectRatio: "16/9"},
  };
  
  // clear the headshot when the component loads
  useEffect((() => {
    dispatch(updateHeadshot(null));
    dispatch(updateVideoId(null));
    dispatch(updateVideoStatus(null));
    dispatch(updateRenderProgress(5));
  }), []);
  
  function flipImage() {
    cropper.scaleX(scaleX * -1, 1);
    setScaleX(scaleX * -1);
  }
  
  function toggleDragMode() {
    if (dragMode === "crop") {
      cropper.setDragMode("move");
      setDragMode("move");
    } else {
      cropper.setDragMode("crop");
      setDragMode("crop");
    }
  }
  
  function showSpinner() {
    setLoading(true);
    setComplete(false);
  }
  
  function hideSpinner() {
    setLoading(false);
    setComplete(true);
  }
  
  function getBlobData() {
    const croppedImage = cropper.getCroppedCanvas().toDataURL();
    const binary = decode(croppedImage.split(",")[1]);
    const array = [];
    
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    
    return new Blob([new Uint8Array(array)], {fileType});
  }
  
  async function uploadHeadshot() {
    showSpinner();
    const blobData = getBlobData();
    const accessToken = await requestApiAccessToken(instance, accounts[0]);
    const uploadURL = await fetchUploadUrl(accessToken);
    const result = await fetch(uploadURL, {
      method: "PUT",
      body: blobData,
    });
    console.log("[FE]video uploaded", result);
    const splitUrl = result.url.split("?").shift();
    console.log("Split URL: " + splitUrl);
    hideSpinner();
    dispatch(updateHeadshot(splitUrl));
    history.push("/step/5");
  }
  
  async function customRequest(req) {
    
    const isLt25M = req.file.size / 1024 / 1024 < MAX_IMAGE_SIZE;
    if (!isLt25M) {
      notification.info({
        description: `Image must smaller than ${MAX_IMAGE_SIZE}MB.`,
      });
    } else {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(req.file);
      setFileType(req.file.type);
    }
    return false;
  }
  
  return (
    <Col justify="center" align="center" span={24}>
      {image && !loading && (
        <Col>
          <CbreSubtitle>Crop your photo as desired</CbreSubtitle>
        </Col>
      )}
      <Col span={24}>
        {image ? (
          !loading && (
            <Cropper
              src={image}
              style={{
                height: 400,
                width: "100%",
                aspectRatio: "40:21",
                marginBottom: "50px",
              }}
              ref={cropRef}
              aspectRatio={40 / 21}
              viewMode={2}
              scalable={true}
              movable={true}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
            />
          )
        ) : (
          <>
            <div style={{display: "flex", justifyContent: 'start'}}>
              <CbreCopy>
                Upload a large, high quality image (~1.5MB, File limit:{" "}
                {MAX_IMAGE_SIZE}MB).
              </CbreCopy>
            </div>
            <Dragger {...draggerProps}>
              {loading && <Spin indicator={spinIcon}/>}
              {!(loading || complete) && (
                <>
                  <p className="ant-upload-drag-icon">
                    <CloudUploadOutlined style={{color: "black"}}/>
                  </p>
                  <CbreCopy level={5}>
                    Drag photo here or{" "}
                    <span className="pseudo-link">browse</span> to upload
                  </CbreCopy>
                </>
              )}
              {complete && (
                <>
                  <p className="ant-upload-drag-icon">
                    <CheckCircleOutlined style={{color: "black"}}/>
                  </p>
                  <p>Headshot upload completed</p>
                </>
              )}
            </Dragger>
          </>
        )}
        {image && loading && (
          <Spin
            indicator={spinIcon}
            style={{color: "white", width: "100%", padding: "60px"}}
          />
        )}
        {!loading && (
          <ActionBar>
            <div style={{display: "flex", justifyContent: "center"}}>
              <Space>
                
                {image ? (
                  <>
                    {/*<CbreButton onClick={toggleDragMode} tooltipText={dragMode === "crop" ? "" : ""}>*/}
                    {/*  {dragMode === "crop" ? "Move" : "Crop"}*/}
                    {/*</CbreButton>*/}
                    {/*<CbreButton onClick={flipImage} tooltipText="mirrors the image horizontally">Flip Image</CbreButton>*/}
                    <CbreButton onClick={uploadHeadshot}>Done</CbreButton>
                  </>
                ) : (
                  <Link to="/step/5" className="form-submit">
                    <CbreButton>Skip this step</CbreButton>
                  </Link>
                )}
              </Space>
            </div>
          </ActionBar>
        )}
      </Col>
    </Col>
  );
}
