import { Row } from "antd";
import styles from "./styles.module.css";

const Content = ({ children }) => {
  return (
    <main className={styles.content}>
      <Row className={styles.row}>{children}</Row>
    </main>
  );
};

export default Content;
