import { Checkbox } from "antd";
import styles from "./styles.module.css";
export const WorkCheck = ({ options, onChange, defaultValue, className }) => {
  return (
    <Checkbox.Group
      className={`${styles.cbreCheckGroup} ${className}`}
      onChange={onChange}
      value={defaultValue}
      options={options}
    />
  );
}