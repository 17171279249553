import React from 'react';
import ReactDOM from 'react-dom';

import "./fonts/calibre/Calibre-Bold.woff";
import "./fonts/calibre/Calibre-Light.woff";
import "./fonts/calibre/Calibre-LightItalic.woff";
import "./fonts/calibre/Calibre-Medium.woff";
import "./fonts/calibre/Calibre-Regular.woff";
import "./fonts/calibre/Calibre-RegularItalic.woff";
import "./fonts/calibre/Calibre-Semibold.woff";

import "./fonts/financier/FinancierDisplay-Medium.woff";
import "./fonts/financier/FinancierDisplay-Medium.woff2";
import "./fonts/financier/FinancierDisplay-MediumItalic.woff";
import "./fonts/financier/FinancierDisplay-MediumItalic.woff2";
import "./fonts/financier/FinancierDisplay-Regular.woff";
import "./fonts/financier/FinancierDisplay-Regular.woff2";
import "./fonts/financier/FinancierDisplay-RegularItalic.woff";
import "./fonts/financier/FinancierDisplay-RegularItalic.woff2";
import "./fonts/financier/FinancierDisplay-Semibold.woff";
import "./fonts/financier/FinancierDisplay-Semibold.woff2";
import "./fonts/financier/FinancierDisplay-SemiboldItalic.woff";
import "./fonts/financier/FinancierDisplay-SemiboldItalic.woff2";

import "antd/dist/antd.css";
import './index.css';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authconfig";

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
