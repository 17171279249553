import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchStatus, pollVideoStatus, uploadVideo } from "./stepsAPI";
const {REACT_APP_ASSET_BASE_URL} = process.env;

const initialState = {
  apiStatus: null,
  status: "idle",
  firstName: null,
  lastName: null,
  email: null,
  stepOne: 0,
  stepTwo: 0,
  stepThree: 0,
  headshot: {},
  renderProgress: 5,
  stepFour: null,
  stepFive: null,
  videoResponse: {},
};

export const uploadVideoConfig = createAsyncThunk(
  "slice/uploadVideoConfig",
  async ({ config: videoConfig, accessToken }, { getState }) => {
    const state = getState();
    videoConfig.email = state.steps.email;
    const response = await uploadVideo(videoConfig, accessToken);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const fetchVideoStatus = createAsyncThunk(
  "slice/fetchVideoStatus",
  async ({ videoId, accessToken }) => {
    const response = await pollVideoStatus(videoId, accessToken);
    if(response.data.status === "success")
    {
      response.data.poster = `${REACT_APP_ASSET_BASE_URL}/digitalassets/${videoId}-poster.jpg`;
      response.data.url = `${REACT_APP_ASSET_BASE_URL}/digitalassets/${videoId}.mp4`;
    }
    // The value we return becomes the `fulfilled` action payload
    console.log(response.data);
    return response.data;
  }
);

export const fetchApiStatus = createAsyncThunk(
  "slice/fetchStatus",
  async () => {
    const response = await fetchStatus();
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const stepsSlice = createSlice({
  name: "steps",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    updateStep: (state, action) => {
      const { step, answer } = action.payload;
      switch (step) {
        case 1:
          state.stepOne = answer;
          break;
        case 2:
          state.stepTwo = answer;
          break;
        case 3:
          state.stepThree = answer;
          break;
        case 4:
          state.stepFour = answer;
          break;
        default:
          break;
      }
    },
    updateHeadshot: (state, action) => {
      state.headshot = action.payload;
    },
    updateFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    updateLastName: (state, action) => {
      state.lastName = action.payload;
    },
    updateEmail: (state, action) => {
      state.email = action.payload;
    },
    updateRenderProgress: (state, action) => {
      state.renderProgress = action.payload;
    },
    updateVideoId: (state, action) => {
      state.videoId = action.payload;
    },
    updateVideoStatus: (state, action) => {
      state.videoStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadVideoConfig.pending, (state) => {
        state.status = "loading";
        state.videoId = null;
        state.videoStatus = null;
      })
      .addCase(uploadVideoConfig.fulfilled, (state, action) => {
        console.log("[FE]action payload:", action.payload);
        state.status = "idle";
        state.videoResponse = action.payload;
        state.videoId = action.payload.response.id;
      })
      .addCase(fetchVideoStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchVideoStatus.fulfilled, (state, action) => {
        state.status = "idle";
        state.videoResponse = action.payload;
        state.videoStatus = action.payload.status;
      })
      .addCase(fetchApiStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchApiStatus.fulfilled, (state, action) => {
        state.status = "idle";
        state.apiStatus = action.payload.message;
      });
  },
});

export const {
  updateEmail,
  updateFirstName,
  updateLastName,
  updateHeadshot,
  updateRenderProgress,
  updateStep,
  updateVideoId,
  updateVideoStatus,
} = stepsSlice.actions;
export const selectHeadshot = (state) => state.steps.headshot;
export const selectRenderProgress = (state) => state.steps.renderProgress;
export const selectVideoResponse = (state) => state.steps.videoResponse;
export const selectVideoId = (state) => state.steps.videoId;
export const selectVideoStatus = (state) => state.steps.videoStatus;
export const selectApiStatus = (state) => state.steps.apiStatus;
export const selectAnswerOne = (state) => state.steps.stepOne;
export const selectAnswerTwo = (state) => state.steps.stepTwo;
export const selectAnswerThree = (state) => state.steps.stepThree;
export const selectAnswerFour = (state) => state.steps.stepFour;
export const selectFirstName = (state) => state.steps.firstName;
export const selectLastName = (state) => state.steps.lastName;
export const selectEmail = (state) => state.steps.email;

export default stepsSlice.reducer;
