import React from "react";
import { Row, Col } from "antd";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { CbreTitle, CbreCopy } from "../../components/CbreTypography";
import copy from "./copy"

const WelcomeLeftWithAuth = () => {
  return (
    <AuthenticatedTemplate>
      <Row>
        <Col>
          <CbreTitle>{copy.title}</CbreTitle>
          <CbreCopy>
            {copy.subtitle}
          </CbreCopy>
        </Col>
      </Row>
    </AuthenticatedTemplate>
  );
};

const WelcomeLeftWithoutAuth = () => {
  return (
    <UnauthenticatedTemplate>
      <Row>
        <Col>
          <CbreTitle>
            Please sign in to continue.
          </CbreTitle>
        </Col>
      </Row>
    </UnauthenticatedTemplate>
  );
};

export function WelcomeLeft() {
  return (
    <>
      <WelcomeLeftWithAuth />
      <WelcomeLeftWithoutAuth />
    </>
  );
}
