import { Select } from "antd";

import styles from "./styles.module.css";

const { Option, OptGroup } = Select;

export const LocationSelect = ({ locations, onChange, disabled }) => {
  const filterOptions = (input, option) => {
    return (option?.value?.toLowerCase() ?? "").includes(input.toLowerCase());
  };
  return (
    <Select
      showSearch
      filterOption={filterOptions}
      onChange={onChange}
      disabled={disabled}
      placeholder="My city"
      className={styles.cbreSelect}
      allowClear={true}
    >
      {locations.map((location) => {
        return (
          <OptGroup label={location.name} key={location.name}>
            {location.options.map((option) => {
              return (
                <Option value={option} key={option}>
                  {option}
                </Option>
              );
            })}
          </OptGroup>
        );
      })}
    </Select>
  );
};

export const RegionSelect = ({ regions, onChange }) => {
  const filterOptions = (input, option) => {
    return (option?.value?.toLowerCase() ?? "").includes(input.toLowerCase());
  };
  return (
    <Select
      showSearch
      filterOption={filterOptions}
      onChange={onChange}
      placeholder="My region"
      className={styles.cbreSelect}
    >
      {regions.map((region) => {
        return (
          <Option value={region.name} key={region.name}>
            {region.name}
          </Option>
        );
      })}
    </Select>
  );
};