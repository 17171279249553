import React from "react";
import { useParams } from "react-router-dom";
import { Left as StepOneLeft, Right as StepOneRight } from "./step-one/StepOne";
import { Left as StepTwoLeft, Right as StepTwoRight } from "./step-two/StepTwo";
import {
  Left as StepThreeLeft,
  Right as StepThreeRight,
} from "./step-three/StepThree";
import {
  Left as StepFourLeft,
  Right as StepFourRight,
} from "./step-four/StepFour";
import {
  Left as StepFiveLeft,
  Right as StepFiveRight,
} from "./step-five/StepFive";
export function StepsLeft() {
  const { id } = useParams();

  function CurrentStep() {
    let currentStep;
    switch (id) {
      case "1":
        currentStep = <StepOneLeft />;
        break;
      case "2":
        currentStep = <StepTwoLeft />;
        break;
      case "3":
        currentStep = <StepThreeLeft />;
        break;
      case "4":
        currentStep = <StepFourLeft />;
        break;
      case "5":
        currentStep = <StepFiveLeft />;
        break;
      default:
        currentStep = <div>default</div>;
        break;
    }
    return currentStep;
  }
  return CurrentStep();
}

export function StepsRight() {
  const { id } = useParams();

  function CurrentStep() {
    let currentStep;
    switch (id) {
      case "1":
        currentStep = <StepOneRight />;
        break;
      case "2":
        currentStep = <StepTwoRight />;
        break;
      case "3":
        currentStep = <StepThreeRight />;
        break;
      case "4":
        currentStep = <StepFourRight />;
        break;
      case "5":
        currentStep = <StepFiveRight />;
        break;
      default:
        currentStep = <div>default</div>;
        break;
    }

    return currentStep;
  }
  return CurrentStep();
}
