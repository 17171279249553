import React from "react";
import { Link } from "react-router-dom";
import { Row } from "antd";

import { ReactComponent as Logo } from "../../cbre_green.svg";
import TopbarRule from "./TopbarRule";

import style from "./styles.module.css"

const logoStyle = {width: 115, height: "auto"}

export function Topbar() {
  return (
    <Row id="cbre-topbar" className={style.topbar}>
      <Link to="/">
      </Link>
      <TopbarRule />
      <Logo style={logoStyle} />
    </Row>
  );
}
